<template>
    <div class="login-main">
        <nav-bar></nav-bar>

        <section class="promocodes-start-mai box-flex">
            <div class="container review-main text-capitalize position-relative box-flex__wrapper">
                <div class="row">
                    <div class="col-md-12 p-0">
                        <div class="center-card | d-flex flex-column">
                            <div class="center-card-box | p-3 border-bottom">
                                <div class="resturant-details d-flex justify-content-between">
                                    <h4 class="text-start">#{{ orders.subscription_id }}</h4>
                                    <p v-if="orders.subscription_is_pause == 1 || orders.subscription_is_pause == 0 && orders.subscription_cancel_date == null"
                                        class="text-center view-invoice" @click="viewInvoice()">{{ $t('View Invoice') }}
                                    </p>
                                </div>
                            </div>
                            <div class="center-card-box | p-3 flex-grow-1 d-flex flex-column">
                                <div class="orders-tab-main" v-if="isSkeletonLoader">
                                    <div class="order-item d-flex align-items-end justify-content-between mb-2 pb-2">
                                        <div class="d-flex align-items-start flex-grow-1">
                                            <skeleton-loader-vue height="120px" width="120px" class="img-cls"
                                                style="border-radius: 5px !important" />
                                            <div
                                                class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                                                <div class="orders-status-main flex-grow-1">
                                                    <h6 class="mb-0"><skeleton-loader-vue type="string" width="24%"
                                                            height="20px" /></h6>
                                                    <p class="mt-1"><skeleton-loader-vue type="string" width="24%"
                                                            height="20px" /></p>
                                                    <div class="promocode mt-2">
                                                        <p><skeleton-loader-vue type="string" width="36%"
                                                                height="20px" /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div><skeleton-loader-vue width="89%" height="31px"
                                                style="border-radius: 30px !important;margin-right: 86px;" /></div>
                                    </div>
                                </div>
                                <div class="orders-tab-main">
                                    <div class="tab-pane active tab-box" v-if="orders != null">
                                        <div class="orders-lst" v-if="orders.restaurant_item_details"
                                            :id="'item-id-' + orders.restaurant_item_details.item_id">
                                            <div
                                                class="order-item d-flex align-items-end justify-content-between mb-2 pb-2">
                                                <div class="d-flex align-items-start">
                                                    <div class="img-cls smallimg-squre">
                                                        <img :src="orders.restaurant_item_details.image" />
                                                    </div>

                                                    <div
                                                        class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                                                        <div class="orders-status-main">
                                                            <h6 class="mb-0">{{
                                                                orders.restaurant_item_details.restaurant_menu_item_name
                                                            }}</h6>
                                                            <p class="mt-1">{{ orders.restaurant_name }}</p>
                                                            <!-- {{ orders.restaurant_item_details.customize[0].option }} -->

                                                            <!-- selected customized showing  -->
                                                            <div class="customized customized-primary d-inline-block"
                                                                v-if="orders.restaurant_item_details.customize && orders.restaurant_item_details.customize.length > 0">
                                                                <p class="customized-lable text-start">Customized</p>
                                                                <div class="selected_type flex-column">
                                                                    <div class="selected-main">
                                                                        <p v-for="(item, i) in orders.restaurant_item_details.customize"
                                                                            :key="i">
                                                                            <span v-for="(ite, j) in item.option"
                                                                                :key="j">
                                                                                <!-- {{ ite.options }} -->
                                                                                {{ ite.selection_name }}
                                                                                <span
                                                                                    v-if="j !== ite.options.selection_name">,</span>
                                                                                <span v-if="ite.options.length > 0"
                                                                                    v-for="(items, j) in ite.options"
                                                                                    :key="j">
                                                                                    {{ items.options }}
                                                                                    {{ items.selection_name }}
                                                                                    <!-- <span
                                                                                        v-if="j !== items.selection_name">,</span> -->
                                                                                </span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- selected customized showing  -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="align-items-end justify-content-between mb-2 pb-2">
                                    <div class="d-flex justify-content-between">
                                        <h5>{{ $t('Subscription Type') }}</h5>
                                        <button
                                            v-if="orders.subscription_is_pause == 1 || orders.subscription_is_pause == 0 && orders.subscription_cancel_date == null"
                                            class="btn edit-button" @click="EditModal(orders.subscription_type)"> {{
                                                $t("Edit
                                            Quantity") }}</button>
                                    </div>
                                    <p class="fw-bold" v-if="orders.subscription_type == 1">{{ $t('Every Day') }}
                                    </p>
                                    <p class="fw-bold" v-if="orders.subscription_type == 3">{{ $t('Custom') }}
                                    </p>
                                    <p class="fw-bold" v-if="orders.subscription_type == 2">{{ $t('on Interval') }}
                                    </p>
                                    <div>
                                        <div>
                                            <!-- <p v-if="orders.subscription_type == 2">
                                            {{ orders.restaurant_item_details.item_quantity }} Quantity
                                            Delivered Every {{ orders.subscription_interval }} Day
                                        </p> -->
                                            <p v-if="orders.subscription_type == 2">
                                                {{ orders.restaurant_item_details.item_quantity }} Quantity Deliver on
                                                Every
                                                {{ orders.subscription_interval }}{{
                                                    getOrdinalSuffix(orders.subscription_interval) }} Day
                                            </p>

                                        </div>
                                        <div>
                                            <p v-if="orders.subscription_type == 1">
                                                {{ orders.restaurant_item_details.item_quantity }} Quantity
                                                Deliver Every Day
                                            </p>
                                        </div>
                                        <div class="date-wrapper | text-start mt-3"
                                            v-if="orders.subscription_type == 3">
                                            <ul class="date-list">
                                                <li class="date-item"
                                                    v-for="(day, index) in orders.subscription_custom_days"
                                                    :key="index">
                                                    <div class="date-day">{{ day.day.slice(0, 3) }}</div>
                                                    <div class="date-num">
                                                        {{ day.quantity }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <h5>{{ $t('Subscription Period') }}</h5>
                                    <div class="row mt-2">
                                        <div class="col">
                                            <p>{{ $t('Starts On') }}</p>
                                            <p>{{ moment(orders.subscription_start_date).format('D MMM, YYYY') }}
                                            </p>
                                        </div>
                                        <div class="col">
                                            <p>{{ $t('Ends On') }}</p>
                                            <p>{{ moment(orders.subscription_end_date).format('D MMM, YYYY') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3">
                                    <h5>{{ $t('Subscription Detail') }}</h5>
                                    <div class="row mt-2">
                                        <div class="d-flex justify-content-between">
                                            <p>{{ $t('Subscription Id') }}</p>
                                            <p>#{{ orders.subscription_id }}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p>{{ $t('Creation Date') }}</p>
                                            <p>{{ moment(orders.subscription_created_at).format('D MMM, YYYY') }}</p>
                                        </div>
                                        <div v-if="orders && (orders.subscription_cancel_date === '' || orders.subscription_cancel_date != null)"
                                            class="d-flex justify-content-between">
                                            <p>{{ $t('Cancel Date') }}</p>
                                            <p>{{ moment(orders.subscription_cancel_date).format('D MMM, YYYY') }}</p>
                                        </div>

                                    </div>
                                </div>

                                <div class="center-card-box | p-3 | row">
                                    <div class="full-btn col" v-if="orders.subscription_cancel_date == null">
                                        <button @click="SubscriptionCancelConfirmation()">Cancel</button>
                                    </div>
                                    <div class="full-btn col"
                                        v-if="orders.subscription_is_pause == 1 && orders.subscription_cancel_date == null">
                                        <button @click="restartSubscription()">Resume</button>
                                    </div>
                                    <div class="full-btn col"
                                        v-if="orders.subscription_is_pause == 0 && orders.subscription_cancel_date == null">
                                        <button @click="pausedSubscription()">Pause</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Resume subscription modal start -->
            <div class="modal fade order-placed" data-bs-backdrop="static" data-bs-keyboard="false"
                id="restartSubscription" tabindex="-1" aria-labelledby="AlertModalLabel" aria-hidden="true"
                ref="AlertModal" style="z-index: 9999;">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('Resume Subscription') }}</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-start">
                            <div class="form-group">
                                <!-- Pause For -->
                                <div class="row mb-3">
                                    <label class="control-label col-md-12">{{ $t('Resume for') }}</label>
                                    <div class="col-md-12">
                                        <select class="custom-select" v-model="restart_data.restart_subscription_for">
                                            <option v-for="option in resume_subscription_option" :value="option.id"
                                                :key="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Confirmation Message -->
                            </div>
                        </div>
                        <div class="modal-footer full-btn d-block">
                            <a href="javascript:void(0);" @click="restart()" data-bs-dismiss="modal">{{ $t('OK') }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Resume subscription modal end -->

            <!-- View invoice modal start -->
            <div class="modal fade order-placed" data-bs-backdrop="static" data-bs-keyboard="false"
                id="viewInvoiceBills" tabindex="-1" aria-labelledby="AlertModalLabel" aria-hidden="true"
                ref="AlertModal" style="z-index: 9999;">
                <div class="modal-dialog modal-dialog-centered modal-lg justify-content-center">
                    <div class="modal-content invoice">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('Generate Invoice') }}</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-start">
                            <div class="form-group">
                                <!-- Pause For -->
                                <div class="row mb-3" v-if="invoice_data.pause_type == 1">
                                    <label class="control-label col-md-12">{{ $t("Select Dates") }}</label>
                                    <div class="col-md-12">
                                        <date-picker v-model="invoice_data.dates" :editable="false" range scrollable
                                            :disabled-date="invoiceDisableDates" :clearable="false" format="DD-MM-YYYY"
                                            placeholder="DD-MM-YYYY" value-type="format" :append-to-body="false"
                                            class="inside-modal-datepicker w-100"></date-picker>
                                    </div>
                                </div>
                                <!-- Confirmation Message -->
                            </div>
                        </div>
                        <div class="modal-footer full-btn d-block">
                            <a href="javascript:void(0);" @click="genrateInvoice()">{{
                                $t('Generate Invoice')
                                }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- View invoice modal end -->

            <!-- Pause Modal start -->
            <div class="modal fade order-placed" id="pausedSubscription" data-bs-backdrop="static"
                data-bs-keyboard="false" tabindex="-1" aria-labelledby="AlertModalLabel" aria-hidden="true"
                ref="AlertModal" style="z-index: 9999;">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('Pause Subscription') }}</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body text-start">
                            <div class="form-group mt-2">
                                <!-- Pause Type -->
                                <div class="row mb-3">
                                    <label class="control-label col-md-12">{{ $t("Pause Type") }}</label>
                                    <div class="col-md-12">
                                        <select class="custom-select" v-model="pause_data.pause_type">
                                            <option v-for="option in pause_options" :value="option.id" :key="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Select Dates -->
                                <div class="row mb-3" v-if="pause_data.pause_type == 1">
                                    <label class="control-label col-md-12">{{ $t("Select Dates") }}</label>
                                    <div class="col-md-12">
                                        <date-picker v-model="pause_data.dates" :editable="false" multiple scrollable
                                            :disabled-date="disableDates" :clearable="false" format="DD-MM-YYYY"
                                            placeholder="DD-MM-YYYY" value-type="format" :append-to-body="false"
                                            class="inside-modal-datepicker w-100"></date-picker>
                                    </div>
                                </div>

                                <!-- Pause For -->
                                <div class="row mb-3">
                                    <label class="control-label col-md-12">{{ $t("Pause For") }}</label>
                                    <div class="col-md-12">
                                        <select class="custom-select" v-model="pause_data.pause_subscription_for">
                                            <option v-for="option in pause_subscription_option" :value="option.id"
                                                :key="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <!-- Pause Reason -->
                                <!-- <div class="row mb-3">
                                    <label for="pauseReason" class="form-label col-md-12">Pause Reason</label>
                                    <div class="col-md-12">
                                        <textarea required class="form-control border border-secondary-subtle"
                                            id="pauseReason" rows="3"></textarea>
                                    </div>
                                </div> -->

                                <!-- Confirmation Message -->
                            </div>
                        </div>

                        <div class="modal-footer full-btn d-block">
                            <a href="javascript:void(0);" @click="paused()" data-bs-dismiss="modal">{{ $t('OK') }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pause Modal end -->

            <!-- CancelSubscription Modal  start-->
            <div class="modal fade order-placed" data-bs-backdrop="static" data-bs-keyboard="false"
                id="SubscriptionCancelConfirmation" tabindex="-1" aria-labelledby="AlertModalLabel" aria-hidden="true"
                ref="AlertModal" style="z-index: 9999;">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content subscription-modal">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('Cancel Subscription') }}</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body text-center">
                            <!-- <p class="mt-3 mb-1" v-if="DisplayAlertData.displayMessage">{{DisplayAlertData.displayMessage}}</p> -->
                            <p class="mt-3 mb-1">
                                {{ $t('Are you sure you want to cancel your subscription? This action cannot be
                                undone.') }}
                            </p>
                        </div>
                        <div class="modal-footer full-btn d-block">
                            <a href="javascript:void(0);" @click="SubscriptionCancel()" data-bs-dismiss="modal">{{
                                $t('OK')
                                }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CancelSubscription Modal  end-->

            <!-- every day Modal -->
            <div>
                <div class="modal fade" id="everyDayModal" tabindex="-1" aria-labelledby="everyDayModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content everyday-modal">
                            <div class="modal-header">
                                <h3 class="modal-title fs-5" id="everyDayModalLabel">{{ $t('Update Quantity') }}
                                </h3>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <ul class="subs-list d-flex flex-column gap-3">
                                        <li class="d-flex gap-3 justify-content-between py-3">
                                            <p>Quantity</p>
                                            <div class="number d-flex align-items-center">
                                                <span class="minus d-flex justify-content-center align-items-center"
                                                    @click="minusItem()">
                                                    -
                                                </span>
                                                <input type="text" :value="tempQUANTITY" readonly />
                                                <span class="plus d-flex justify-content-center align-items-center"
                                                    @click="plusItem()">
                                                    +
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="modal-footer full-btn d-block">
                                <button @click="setType(1)">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- interval Modal -->
            <div class="modal fade" id="deliveryDayModal" tabindex="-1" aria-labelledby="deliveryDayModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fs-5" id="deliveryDayModalLabel">
                                {{ $t('Update Quantity') }}
                            </h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <ul class="subs-list form__group | d-flex flex-column">
                                    <li class="form__radio-group | d-flex gap-3 justify-content-between"
                                        v-for="(intervalOption, optionIndex) in intervalOptions"
                                        :key="'intervalOption_' + optionIndex">
                                        <input type="radio" :id="'classic' + optionIndex + 'Option' + optionIndex"
                                            :value="intervalOption" class="form__radio-input" name="interval_day"
                                            :checked="intervalOption.isChecked" :disabled="intervalOption.disabled"
                                            @click="updateQuantity(optionIndex, intervalOption.value, intervalOption.isModal)" />
                                        <label :for="'classic' + optionIndex + 'Option' + optionIndex"
                                            class="form__label-radio">
                                            <span class="form__radio-button"></span>
                                            {{ intervalOption.isModal && intervalOption.isChecked ? `Every
                                            ${orders.subscription_interval}th day` : intervalOption.label }}
                                        </label>
                                        <div class="number d-flex align-items-center">
                                            <span class="minus d-flex justify-content-center align-items-center"
                                                @click="minusIntervalItem(optionIndex, intervalOption.value)"
                                                :disabled="intervalOption.disabled">-</span>
                                            <input readonly type="text" :value="intervalOption.quantity" />
                                            <span class="plus d-flex justify-content-center align-items-center"
                                                @click="plusIntervalItem(optionIndex, intervalOption.value, intervalOption.isModal)"
                                                :disabled="intervalOption.disabled">+</span>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="modal-footer full-btn d-block">
                            <button @click="setType(2)">Submit</button>
                        </div>
                    </div>
                </div>
            </div>



            <!--custome Modal -->
            <div class="modal fade" id="deliveryDayTwoModal" tabindex="-1" aria-labelledby="deliveryDayTwoModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title fs-5" id="deliveryDayTwoModalLabel">
                                {{ $t('Update Quantity') }}
                            </h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <ul class="subs-list custom-checkbox | d-flex flex-column">
                                    <li class="d-flex gap-3 justify-content-between align-items-center"
                                        v-for="(day, index) in selectedDays" :key="index">
                                        <div class="form-group">

                                            <input type="checkbox" :id="'checkbox' + index" class="form-check-input"
                                                v-model="selectedDays[index].isChecked"
                                                @change="updateSelectedDays(index)" />
                                            <label :for="'checkbox' + index" class="form-check-label">
                                                {{ day.day }}
                                            </label>
                                        </div>
                                        <div class="number d-flex align-items-center">
                                            <span class="minus d-flex justify-content-center align-items-center"
                                                @click="minusCustomItem(index)">-</span>
                                            <input readonly type="text" :value="selectedDays[index].quantity" />
                                            <span class="plus d-flex justify-content-center align-items-center"
                                                @click="plusCustomItem(index)">+</span>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div class="modal-footer full-btn d-block">
                            <button @click="setType(3)">Update</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade order-placed" id="freeCoupen" tabindex="-1" aria-labelledby="orderplacedLabel"
                aria-hidden="true" ref="freeCoupen" style="z-index: 9999;">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body text-center">
                            <p class="mt-3 mb-1">
                                {{ $t("Free coupne order not apply your wallet amount deduct") }}.
                            </p>
                        </div>
                        <div class="modal-footer full-btn d-block">
                            <a href="javascript:void(0);" data-bs-dismiss="modal">{{
                                $t('Ok') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import NavBar from "../components/NavBar.vue";
import DeliveryTime from "../components/DeliveryTime.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";
import VueSkeletonLoader from "skeleton-loader-vue";
import noImge from "@/assets/images/place_holder.png";
import { requiredIf } from "vuelidate/lib/validators";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Vue from "vue";
import SubscriptionModal from './SubscriptionModal.vue';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";




export default {
    components: {
        NavBar,
        Footer,
        SubscriptionModal,
        DatePicker,
        'skeleton-loader-vue': VueSkeletonLoader
    },
    data() {
        return {
            moment: moment,
            everyDay_quantity: 0,
            orders: {},
            pauseOrRestart: 0,
            subscription_type: 0,
            subscriptionModalOPen: false,
            isSkeletonLoader: true,
            tempQUANTITY: 0,
            selectedPauseOption: null,
            selectedResumeOption: null,
            customQuantity: 0,
            // getSubscription: [],
            pause_data: {
                dates: [],
                pause_type: 1,
                pause_reason: "",
                pause_subscription_for: 1,
            },

            invoice_data: {
                dates: [],
                pause_type: 1,
                pause_reason: "",
                pause_subscription_for: 1,
            },

            restart_data: {
                restart_subscription_for: 1,
            },
            pause_options: [
                { id: 1, name: "Pause Subscription for Specific Days" },
                { id: 2, name: "Pause Subscription Until Resumed" },
            ],
            pause_subscription_option: [
                { id: 1, name: "Pause This Subscription Only" },
                { id: 2, name: "Pause All Subscriptions" },
            ],
            resume_subscription_option: [
                { id: 1, name: "Resume This Subscription Only" },
                { id: 2, name: "Resume All Subscriptions" },
            ],

            intervalOptions: [
                { value: "2", label: "Every 2nd day", quantity: 1, isChecked: true, isModal: false },
                { value: "3", label: "Every 3rd day", quantity: 0, isChecked: false, isModal: false },
                { value: "4", label: "Every 4th day", quantity: 0, isChecked: false, isModal: false },
                { value: "n", label: "Every nth day", quantity: 0, isChecked: false, isModal: true },
            ],
            selectedDays: [
                { key: "Sun", day: "Sunday", isChecked: true, quantity: 1 },
                { key: "Mon", day: "Monday", isChecked: true, quantity: 1 },
                { key: "Tue", day: "Tuesday", isChecked: true, quantity: 1 },
                { key: "Wed", day: "Wednesday", isChecked: true, quantity: 1 },
                { key: "Thu", day: "Thursday", isChecked: true, quantity: 1 },
                { key: "Fri", day: "Friday", isChecked: true, quantity: 1 },
                { key: "Sat", day: "Saturday", isChecked: true, quantity: 1 },
            ],

            date_day: [
                { key: "Sun", day: "Sunday", value: 0 },
                { key: "Mon", day: "Monday", value: 0 },
                { key: "Tue", day: "Tuesday", value: 0 },
                { key: "Wed", day: "Wednesday", value: 0 },
                { key: "Thu", day: "Thursday", value: 0 },
                { key: "Fri", day: "Friday", value: 0 },
                { key: "Sat", day: "Saturday", value: 0 },
            ],
            startDate: null,
            endDate: null,
        };
    },

    watch: {
        'invoice_data.dates'(newDates) {
            if (newDates.length === 2) {
                this.startDate = moment(newDates[0], "DD-MM-YYYY").format("DD-MM-YYYY");
                this.endDate = moment(newDates[1], "DD-MM-YYYY").format("DD-MM-YYYY");  // Second date in the range
            } else {
                this.startDate = null;
                this.endDate = null;
            }
        },
        'orders.pause_dates': {
            handler(newPauseDates) {
                this.initializePauseDates();
            },
            immediate: true // Run the handler immediately on component mount
        }
    },

    computed: {
        ...mapGetters("product", ["getSubscription",]),
    },

    mounted() {
        setTimeout(() => {
            if (this.$auth.user) {
                this.orderDetails();
                this.isSkeletonLoader = false
            } else {
                this.$router.push({ name: "sign-in" });
            }
        }, 500);
    },
    methods: {
        ...mapActions("order", ["viewSubscriptionDetail", "SubscriptionRestartPaused", "CancelSubscription", "EditSubscription", "createInvoice"]),


        EditModal() {
            if (this.orders.subscription_type == 1) {
                this.tempQUANTITY = this.orders.restaurant_item_details.item_quantity;
                // Open the modal using Bootstrap's API
                const modal = new bootstrap.Modal(document.getElementById('everyDayModal'));
                modal.show();
            } else if (this.orders.subscription_type == 2) {
                this.intervalOptions.forEach((option) => {
                    if (
                        option.value == String(this.orders.subscription_interval) ||
                        (option.value == "n" && this.orders.subscription_interval > 4)
                    ) {
                        // Match condition
                        option.quantity = this.orders.restaurant_item_details.item_quantity || 1;
                        if (option.value == "n") {
                            option.quantity = this.orders.restaurant_item_details.item_quantity;
                            option.value = this.orders.subscription_interval;
                        }
                        option.isChecked = true;
                        option.disabled = false; // Enable matched option
                    } else {
                        // Unmatched condition
                        option.isChecked = false;
                        option.quantity = 0;
                        option.disabled = true; // Disable unmatched options
                    }
                });

                // Show the modal
                $('#deliveryDayModal').modal("show");

                // this.intervalOptions.forEach((option) => {
                //     // Check for a specific match or handle "Every nth day"
                //     if (
                //         option.value == String(this.orders.subscription_interval) ||
                //         (option.value == "n" && this.orders.subscription_interval > 4)
                //     ) {
                //         // Update the quantity based on item_quantity
                //         option.quantity = this.orders.restaurant_item_details.item_quantity || 1;
                //         // If "Every nth day", set the quantity to subscription_interval
                //         if (option.value == "n") {
                //             option.quantity = this.orders.restaurant_item_details.item_quantity;
                //             option.value = this.orders.subscription_interval;
                //         }
                //         option.isChecked = true;
                //     } else {
                //         option.isChecked = false;
                //         option.quantity = 0; // Reset quantities for unmatched options
                //     }
                // });
                // $('#deliveryDayModal').modal("show");
            } else if (this.orders.subscription_type == 3) {
                this.selectedDays = this.orders.subscription_custom_days.map((day) => ({
                    day: day.day,
                    isChecked: day.quantity > 0, // Automatically check based on quantity
                    quantity: day.quantity > 0 ? day.quantity : 0,
                }));
                $('#deliveryDayTwoModal').modal("show");



                // this.selectedDays = this.orders.subscription_custom_days.map((day) => ({
                //     key: day.key,
                //     day: day.day,
                //     isChecked: day.quantity > 0,
                //     quantity: day.quantity > 0 ? day.quantity : 0,
                // }));

                // this.selectedDays = this.orders.subscription_custom_days.length
                //     ? this.orders.subscription_custom_days.map(day => ({
                //         key: day.key,
                //         day: day.day,
                //         isChecked: day.quantity > 0, // Check if the day is active based on quantity
                //         quantity: day.quantity,
                //     })) : []

                // $('#deliveryDayTwoModal').modal("show");
            }
        },

        plusItem(qty, index) {
            this.tempQUANTITY = Number(this.tempQUANTITY) + 1;
            // if (this.getSubscription[0].itemTotalQuantity < this.tempQUANTITY) {
            // } else {
            //     alert(this.$t("Maximum items reached"));
            // }
        },
        minusItem() {
            if (this.tempQUANTITY > 0) { // Prevent quantity from going below 0
                this.tempQUANTITY = Number(this.tempQUANTITY) - 1;
            }
        },

        // plusItem(qty, index) {
        //     // this.getSubscription.forEach((item) => {
        //     // })
        //     // this.everyDay_quantity = item.quantity
        //     // this.tempQUANTITY = this.orders.restaurant_item_details.item_quantity;
        //     this.tempQUANTITY = Number(this.tempQUANTITY) + 1;
        //     // if (this.getSubscription[0].itemTotalQuantity < this.tempQUANTITY) {
        //     //     // this.orders.restaurant_item_details.item_quantity++;
        //     //     // this.everyDay_quantity = this.orders.restaurant_item_details.item_quantity;
        //     // } else {
        //     //     alert(this.$t("Maximum items reached"));
        //     // }
        // },



        updateQuantity(optionIndex) {
            this.intervalOptions.forEach((option, i) => {
                if (i == optionIndex) {
                    if (option.quantity == 0) {
                        option.quantity = 1;
                    }
                    option.isChecked = true;
                } else {
                    option.isChecked = false;
                    option.quantity = 0;
                }
            });
        },

        plusIntervalItem(index) {
            const option = this.intervalOptions[index];
            if (option.disabled) return; // Prevent changes if the option is disabled

            if (option.quantity == 0) {
                option.quantity++;
                option.isChecked = true;
                this.updateQuantity(index); // Update quantity on the change
            } else {
                option.quantity++;
            }
        },
        minusIntervalItem(index) {
            const option = this.intervalOptions[index];
            if (option.disabled) return; // Prevent changes if the option is disabled

            if (option.quantity > 1) {
                option.quantity--;
            } else if (option.quantity == 1) {
                option.quantity = 0;
                option.isChecked = false; // Uncheck the option when quantity reaches 0
            }
        },

        // plusIntervalItem(index) {
        //     if (this.intervalOptions[index].quantity === 0) {
        //         this.intervalOptions[index].quantity++;
        //         this.intervalOptions[index].isChecked = true;
        //         this.updateQuantity(index)
        //     } else {
        //         this.intervalOptions[index].quantity++;
        //     }
        // },
        // minusIntervalItem(index) {
        //     if (this.intervalOptions[index].quantity > 1) {
        //         this.intervalOptions[index].quantity--;
        //     }
        //     if (this.intervalOptions[index].quantity === 0) {
        //         this.intervalOptions[index].isChecked = false;
        //     }
        // },

        // update Quantity custom

        updateSelectedDays(index) {
            const day = this.selectedDays[index];
            if (day.isChecked) {
                day.quantity = 1; // Set quantity to 1 if checked
            } else {
                day.quantity = 0; // Reset quantity to 0 if unchecked
            }
        },

        plusCustomItem(index) {
            const selectedDay = this.selectedDays[index];
            if (!selectedDay.isChecked) {
                selectedDay.isChecked = true;
            }
            selectedDay.quantity++;
        },

        minusCustomItem(index) {
            const selectedDay = this.selectedDays[index];
            if (selectedDay.quantity <= 0) return; // Prevent changes if disabled or quantity is 0
            selectedDay.quantity--;
            if (selectedDay.quantity == 0) {
                selectedDay.isChecked = false;
            }
        },

        UpdateSubscriptionQuantity() {
            this.EditSubscription({
                vendor_id: this.$auth.getVendorId(),
                subscription_id: this.orders.subscription_id,
                subscription_type: this.orders.subscription_type,
                restaurant_item_details: JSON.stringify(this.orders.restaurant_item_details),
                subscription_custom_days: JSON.stringify(this.orders.subscription_custom_days),
                is_wallet_check: this.orders.restaurant_item_details.item_quantity > 1 || this.customQuantity ? true : false,
            }).then((data) => {
                if (data.code == 200) {
                    Vue.toasted.success("Subscription Update Successfully", {
                        duration: 2000,
                    });
                } else if (data.code == 101) {
                    Vue.toasted.error(data.msg, {
                        duration: 2000,
                    });
                }
            })
        },


        setType(type) {
            if (type == 1) {
                // this.orders = e;
                if (this.tempQUANTITY <= 0) {  // Check if quantity is zero or negative
                    Vue.toasted.error("Quantity cannot be less than 0", {
                        duration: 2000,
                    });
                    return; // Stop execution if the condition is met
                }
                this.orders.restaurant_item_details.item_quantity = this.tempQUANTITY
                // if (this.orders.restaurant_item_details.item_quantity > 1) {
                //     $("#freeCoupen").modal("show");
                //     return;
                // }
                this.UpdateSubscriptionQuantity()
                // this.orders.subscription_interval = e.value
                $('#everyDayModal').modal("hide");

            }

            if (type == 2) {
                this.intervalOptions.forEach((e) => {
                    if (e.isChecked == true) {
                        // this.orders = e;
                        this.orders.restaurant_item_details.item_quantity = e.quantity
                        this.orders.subscription_interval = e.value
                        this.UpdateSubscriptionQuantity()
                    }
                });
            }
            // if (type == 3) {
            //     const selectedDaysData = this.selectedDays
            //         // .filter((day) => day.isChecked) // Include only checked days
            //         .map((day) => ({
            //             day: day.day,
            //             quantity: day.quantity,
            //             // key: day.key,
            //         }));



            //     // Example: Assign the filtered and formatted data to the `orders` variable
            //     this.orders.subscription_custom_days = selectedDaysData;
            //     this.orders.subscription_custom_days.forEach((item) => {
            //         if (item.quantity <= 0) {
            //             alert("nvnvcnvcnzxbv")
            //         }
            //     })
            //     $('#deliveryDayTwoModal').modal("hide");
            // }

            if (type == 3) {
                this.customQuantity = 0
                this.selectedDays.forEach((item) => {
                    if (item.isChecked == true && item.quantity > 1) {
                        this.customQuantity = item.quantity
                    }
                })

                const selectedDaysData = this.selectedDays
                    .map((day) => ({
                        day: day.day,
                        quantity: day.quantity,
                    }));
                // Check if all selected days are unchecked or have quantity <= 0
                const allUncheckedOrInvalid = selectedDaysData.every((item) => item.quantity <= 0);
                if (allUncheckedOrInvalid) {
                    Vue.toasted.error("Quantity cannot be less than 0", {
                        duration: 2000,
                    });
                    return;
                }
                this.orders.subscription_custom_days = selectedDaysData;
                this.UpdateSubscriptionQuantity()
                // Hide the modal
                $('#deliveryDayTwoModal').modal("hide");
            }
        },

        restartSubscription() {
            $('#restartSubscription').modal("show");
        },
        pausedSubscription() {
            $('#pausedSubscription').modal("show");
        },
        SubscriptionCancelConfirmation() {
            $('#SubscriptionCancelConfirmation').modal("show");
        },

        viewInvoice() {
            $('#viewInvoiceBills').modal("show");
        },

        getOrdinalSuffix(n) {
            let j = n % 10,
                k = n % 100;
            if (j == 1 && k != 11) {
                return "st";
            }
            if (j == 2 && k != 12) {
                return "nd";
            }
            if (j == 3 && k != 13) {
                return "rd";
            }
            return "th";
        },

        async orderDetails() {
            if (this.isBase64(this.$route.params.subscription_id)) {
                const response = await this.viewSubscriptionDetail({
                    vendor_id: this.$auth.getVendorId(),
                    subscription_id: this.base64_decode(this.$route.params.subscription_id),
                    is_langauge: this.$store.state.lang.locale,
                });

                if (response.code === 200) {
                    this.orders = response.subscription;
                    if (this.orders.subscription_type == 1) {
                        this.tempQUANTITY = this.orders.restaurant_item_details.item_quantity;
                    }
                    this.loading = false;
                }
            } else {
                this.orders = {};
                this.loading = false;
            }
        },

        async paused() {
            if (this.pause_data.pause_type == 1) {
                // Ensure this.pause_data.dates is valid and has elements
                if (!this.pause_data.dates || !Array.isArray(this.pause_data.dates) || this.pause_data.dates.length <= 0) {
                    Vue.toasted.error("Please select the dates  ", {
                        duration: 5000,
                    });
                    return;
                }
            }
            this.pauseOrRestart = 1;
            await this.pausedRestart();
            this.orderDetails();
        },

        initializePauseDates() {
            if (this.orders.pause_dates && this.orders.pause_dates.length > 0) {
                // Format the dates to match the date-picker format
                this.pause_data.dates = JSON.parse(this.orders.pause_dates)
                this.pause_data.dates.map(date =>
                    this.moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")
                );
            }
        },

        disableDates(date) {
            // Check if the subscription data is available
            if (this.orders != null) {
                const startDate = this.orders.subscription_start_date
                    ? this.moment(this.orders.subscription_start_date, "YYYY-MM-DD")
                    : null;
                const endDate = this.orders.subscription_end_date
                    ? this.moment(this.orders.subscription_end_date, "YYYY-MM-DD")
                    : null;

                const currentDate = this.moment(date); // Convert date-picker's date to moment
                const today = this.moment().startOf("day"); // Today's date, starting at midnight

                if (startDate) {
                    // Disable dates before start_date or dates before today (whichever is later)
                    const effectiveStartDate = startDate.isAfter(today) ? startDate : today;

                    if (endDate) {
                        // Disable dates outside the range [effectiveStartDate, endDate]
                        return (
                            currentDate.isBefore(effectiveStartDate) || currentDate.isAfter(endDate)
                        );
                    } else {
                        // If no end_date, allow selection for 30 days from effectiveStartDate
                        const maxDate = effectiveStartDate.clone().add(30, "days");
                        return (
                            currentDate.isBefore(effectiveStartDate) || currentDate.isAfter(maxDate)
                        );
                    }
                }
            }

            // Allow all dates if start_date and end_date are null
            return false;
        },
        invoiceDisableDates(date) {
            // Check if the subscription data is available
            if (this.orders != null) {
                const startDate = this.orders.subscription_start_date
                    ? this.moment(this.orders.subscription_start_date, "YYYY-MM-DD")
                    : null;
                const endDate = this.orders.subscription_end_date
                    ? this.moment(this.orders.subscription_end_date, "YYYY-MM-DD")
                    : null;

                const currentDate = this.moment(date); // Convert date-picker's date to moment

                if (startDate && endDate) {
                    // Disable dates outside the range [startDate, endDate]
                    return currentDate.isBefore(startDate) || currentDate.isAfter(endDate);
                }
            }

            // Allow all dates if start_date and end_date are null
            return false;
        },

        genrateInvoice() {
            if (this.invoice_data.pause_type == 1) {
                // Ensure this.pause_data.dates is valid and has elements
                if (!this.invoice_data.dates || !Array.isArray(this.invoice_data.dates) || this.invoice_data.dates.length <= 0) {
                    Vue.toasted.error("Please select the dates", {
                        duration: 5000,
                    });
                    return;
                }
            }
            let host = window.location.hostname == "localhost" ? "dev.deonde.co" : window.location.hostname;
            let vendorId = this.$auth.getVendorId(); // Get the vendor ID
            let subscriptionId = this.orders.subscription_id; // Get the subscription ID
            let startDate = this.startDate; // Start date
            let endDate = this.endDate; // End date
            function toBase64(str) {
                return btoa(str);
            }
            let encodedVendorId = toBase64(vendorId.toString());
            let encodedSubscriptionId = toBase64(subscriptionId.toString());
            let url = `https://${host}/deondeapi/print-subscription-invoice/${encodedVendorId}/${encodedSubscriptionId}/${startDate}/${endDate}`;
            window.open(url, '_blank');
            $('#viewInvoiceBills').modal("hide");
            // this.createInvoice({
            //     vendor_id: this.$auth.getVendorId(),
            //     subscription_id: this.orders.subscription_id,
            //     start_date: this.startDate,
            //     end_date: this.endDate,
            // }).then((data) => {
            //     if (data.code == 200) {
            //         window.open(data.result.url, '_blank');
            //     }
            // })
            // $('#viewInvoiceBills').modal("hide");
        },


        async restart() {
            this.pauseOrRestart = 0;
            await this.pausedRestart();
            this.orderDetails();
        },

        async pausedRestart() {
            const response = await this.SubscriptionRestartPaused({
                vendor_id: this.$auth.getVendorId(),
                subscription_id: this.base64_decode(this.$route.params.subscription_id),
                is_langauge: this.$store.state.lang.locale,
                is_pause: this.pauseOrRestart,
                subscription_pause_reason: null,
                pause_dates: this.pause_data.pause_type == 1 && this.pause_data.dates ? JSON.stringify(this.pause_data.dates) : JSON.stringify([]),
                // customer_id: this.pause_subscription_option[2].id || this.resume_subscription_option[2].id ? this.$auth.user.user_id : ''
                customer_id: this.pause_data.pause_subscription_for == 2 || this.restart_data.restart_subscription_for == 2 ? this.$auth.user.user_id : ''
            });
            if (response.code == 200) {
                this.orders = response.subscription;
                this.loading = false;
            } else {
                // Vue.toasted.error(response.msg, {
                //     duration: 2000,
                // });
            }
        },

        async SubscriptionCancel() {
            const response = await this.CancelSubscription({
                vendor_id: this.$auth.getVendorId(),
                subscription_id: this.base64_decode(this.$route.params.subscription_id),
                is_langauge: this.$store.state.lang.locale,
                is_cancel: 1,
            });

            if (response.code === 200) {
                this.orders = response.subscription;
                this.loading = false;
                await this.orderDetails();
            } else {
                Vue.toasted.error(response.msg, {
                    duration: 2000,
                });
            }
        },

        isBase64(str) {
            if (str === "" || str.trim() === "") {
                return false;
            }
            try {
                return btoa(atob(str)) == str;
            } catch (err) {
                return false;
            }
        },
    },
};
</script>

<style scoped>
.modal-btn.rounded-pill.active {
    background-color: #868e96;
    /* Background color when active */
}

.text-primary-400 {
    color: rgba(var(--theme-primary));
}

input[type="date"] {
    outline: transparent;
}

.card {
    border-radius: 15px;
}

.date-label {
    font-size: 20px;
}

.modal-btn {
    padding: 10px 20px;
    border-radius: 5rem;
    background-color: #e9e9e9;
    border: none;
}

.date-wrapper {
    overflow: auto;
}

.date-list {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 3px;
}

.date-item {
    background-color: #e9e9e9;
    border-radius: 10px;
    min-width: 50px;
    text-align: center;
    font-size: 14px;
}

.date-day {
    background-color: rgba(var(--theme-primary));
    color: #fff;
    padding: 6px;
    border-radius: 10px;
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-num {
    padding: 6px;
}

.subs-list .form-group {
    border: none;
}

.subs-list li {
    border-bottom: 1px solid var(--theme-secondary);
}

a.cart-btn,
a.cart-btn:hover {
    margin-top: 3px;
    color: #ff4848 !important;
    background: #fff;
    padding: 2px 14px !important;
    font-size: 14px;
    border: 1px solid !important;
    border-radius: 30px;
}


.inside-modal-datepicker .date-picker-container {
    z-index: 1050;
    /* Match the modal's z-index */
    position: relative;
    /* Keep it within the modal */
}

.invoice {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 70%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.view-invoice {
    border: 1px solid;
    border-radius: 20px;
    width: 21%;
    cursor: pointer;
}

.edit-button {
    border: 1px solid;
    border-radius: 20px;
    width: 25%;
    cursor: pointer;
}

.everyday-modal {
    height: 35vh;
}
</style>
